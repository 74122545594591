function dispatchListChangedEvent(listContainer) {
  const event = new CustomEvent("list-changed");
  listContainer.dispatchEvent(event);
}

function moveUp(listItem) {
  const listContainer = document.getElementById("orderList");
  const previousSibling = listItem.previousElementSibling;
  if (previousSibling) {
    listContainer.insertBefore(listItem, previousSibling);
    dispatchListChangedEvent(listContainer);
  }
}

function moveDown(listItem) {
  const listContainer = document.getElementById("orderList");
  const nextSibling = listItem.nextElementSibling;
  if (nextSibling) {
    listContainer.insertBefore(nextSibling, listItem);
    dispatchListChangedEvent(listContainer);
  }
}

function moveToTop(listItem) {
  const listContainer = document.getElementById("orderList");
  listContainer.insertBefore(listItem, listContainer.firstChild);
  dispatchListChangedEvent(listContainer);
}

function moveToBottom(listItem) {
  const listContainer = document.getElementById("orderList");
  listContainer.appendChild(listItem);
  dispatchListChangedEvent(listContainer);
}

function positionMenu(menu, button) {
  const menuWidth = menu.offsetWidth;
  const menuHeight = menu.offsetHeight;
  const buttonRect = button.getBoundingClientRect();
  const windowHeight = window.innerHeight;

  if (window.innerWidth < 1310) {
    menu.style.left = "0";
    menu.style.top = button.offsetHeight + "px";

    if (buttonRect.bottom + menuHeight > windowHeight) {
      menu.style.top = `-${menuHeight}px`;
    }
  } else {
    menu.style.left = `-${menuWidth}px`;

    if (buttonRect.top + menuHeight > windowHeight) {
      menu.style.top = `-${menuHeight - buttonRect.height}px`;
    }
  }
}

export function initializeMoveOrders(element) {
  const menuButtons = document.querySelectorAll(".menu-button-container");
  let currentMenu = null;
  let currentMenuButton = null;

  menuButtons.forEach((button) => {
    button.addEventListener("click", function (event) {
      event.stopPropagation();

      const listItem = this.closest(".list-item");
      const listItemId = listItem.dataset.itempk;

      if (currentMenu && currentMenuButton === this) {
        currentMenu.remove();
        currentMenu = null;
        currentMenuButton = null;
        return;
      } else if (currentMenu) {
        currentMenu.remove();
        currentMenu = null;
        currentMenuButton = null;
      }

      const menu = document.createElement("ul");
      menu.className = "menu show";
      menu.dataset.listItemId = listItemId;

      const moveUpItem = document.createElement("li");
      moveUpItem.textContent = gettext("Move Up");
      moveUpItem.dataset.action = "move-up";
      menu.appendChild(moveUpItem);

      const moveToTopItem = document.createElement("li");
      moveToTopItem.textContent = gettext("Move to Top");
      moveToTopItem.dataset.action = "move-top";
      menu.appendChild(moveToTopItem);

      const moveDownItem = document.createElement("li");
      moveDownItem.textContent = gettext("Move Down");
      moveDownItem.dataset.action = "move-down";
      menu.appendChild(moveDownItem);

      const moveToBottomItem = document.createElement("li");
      moveToBottomItem.textContent = gettext("Move to Bottom");
      moveToBottomItem.dataset.action = "move-bottom";
      menu.appendChild(moveToBottomItem);

      this.appendChild(menu);
      positionMenu(menu, this);

      currentMenu = menu;
      currentMenuButton = this;

      menu.addEventListener("click", (menuEvent) => {
        menuEvent.stopPropagation();
        const target = menuEvent.target;
        if (target.tagName === "LI") {
          const action = target.dataset.action;
          if (listItem) {
            switch (action) {
              case "move-up":
                moveUp(listItem);
                break;
              case "move-top":
                moveToTop(listItem);
                break;
              case "move-down":
                moveDown(listItem);
                break;
              case "move-bottom":
                moveToBottom(listItem);
                break;
            }
            if (currentMenu) {
              currentMenu.remove();
              currentMenu = null;
              currentMenuButton = null;
            }
          }
        }
      });
    });
  });

  document.addEventListener("click", function (event) {
    if (
      currentMenu &&
      !event.target.closest(".menu") &&
      !event.target.closest(".menu-button-container")
    ) {
      currentMenu.remove();
      currentMenu = null;
      currentMenuButton = null;
    }
  });

  window.addEventListener("resize", () => {
    if (currentMenu && currentMenuButton) {
      positionMenu(currentMenu, currentMenuButton);
    }
  });
}

export default {
  initializeMoveOrders,
};
